@font-face {
  font-family: "GothamProBlack";
  font-weight: 700;
  font-style: normal;
  src: url(../fonts/GothamProBlack.ttf) format("truetype");
}

@font-face {
  font-family: "MyriadProRegular";
  font-weight: 400;
  font-style: normal;
  src: url(../fonts/MyriadProRegular.otf) format("opentype");
}

@font-face {
  font-family: "MyriadProBold";
  font-weight: 600;
  font-style: normal;
  src: url(../fonts/MyriadProBold.otf) format("opentype");
}