// @import "~bootstrap/scss/bootstrap";
@import "vars";
@import "fonts";
@import "checbox";
@import "modal";
@import "~aos/dist/aos.css";
body {
  font-family: MyriadProRegular, sans-serif;
}
html, body{
  height: 100%;
}
.alert-danger{
  padding: 10px 15px;
  margin-bottom: 15px;
  display: none;
}
.bg-dark {
  background: transparent !important;
}

h1, h2 {
  font-family: GothamProBlack, sans-serif;
  text-transform: uppercase;
}

h1 {
  font-size: 50px;
  line-height: 75px;
  margin-bottom: 52px;
}

h2 {
  font-size: 45px;
  text-align: center;
  line-height: 68px;
  &.bordered-bottom {
    position: relative;
    &:after {
      content: ' ';
      position: absolute;
      height: 5px;
      width: 360px;
      bottom: -10px;
      left: 50%;
      margin-left: -180px;
      background: $colorLightRed;
    }
  }
}

h4 {
  font-size: 30px;
  font-family: MyriadProBold, sans-serif;
}

input[type=text]::-webkit-input-placeholder {
  text-indent: 0;
  transition: text-indent 0.3s ease;
}

input[type=text]::-moz-placeholder {
  text-indent: 0;
  transition: text-indent 0.3s ease;
}

input[type=text]:-moz-placeholder {
  text-indent: 0;
  transition: text-indent 0.3s ease;
}

input[type=text]:-ms-input-placeholder {
  text-indent: 0;
  transition: text-indent 0.3s ease;
}

input[type=text]:focus::-webkit-input-placeholder {
  text-indent: 500px;
  transition: text-indent 0.3s ease;
}

input[type=text]:focus::-moz-placeholder {
  text-indent: 500px;
  transition: text-indent 0.3s ease;
}

input[type=text]:focus:-moz-placeholder {
  text-indent: 500px;
  transition: text-indent 0.3s ease;
}

input[type=text]:focus:-ms-input-placeholder {
  text-indent: 500px;
  transition: text-indent 0.3s ease;
}

::-webkit-input-placeholder {
  color: #5a5a5a;
  font-size: 15px;
}

::-moz-placeholder {
  color: #5a5a5a;
  font-size: 15px;
}

/* Firefox 19+ */
:-moz-placeholder {
  color: #5a5a5a;
  font-size: 15px;
}

/* Firefox 18- */
:-ms-input-placeholder {
  color: #5a5a5a;
  font-size: 15px;
}

.btn-danger {
  background: $colorRed;
  font-family: MyriadProBold, sans-serif;
  font-size: 24px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: rgb(192, 2, 2);
    box-shadow: 0 15px 50px 0 rgba(0, 1, 6, 0.25);
  }
}

.form-control {
  border-radius: 28px;
  padding: 9px 20px;
  &[type="text"] {
    border: 3px solid #9a9a9a;
  }
  &[type=submit] {
    padding: 7px 20px 2px;
    box-shadow: 0 15px 50px 0 rgba(0, 1, 6, 0.25);
    height: 48px;
  }
  &:focus {
    outline: none !important;
    box-shadow: 0 0 0 0.2rem rgba(154, 154, 154, .25);
  }
}

.bg-grey {
  background: #ececec;
}

.sticky {
  padding: 5px 0 !important;
  background: rgba(0, 0, 0, 0.85) !important;
}

.navbar {
  padding: .5rem 0;
  margin-left: -15px;
  margin-right: -15px;
  .container {
    position: relative;
  }
}

.navbar-nav {
  font-family: MyriadProRegular, sans-serif;
  font-size: 20px;
  flex-basis: 100%;
  justify-content: center;
}

nav {
  transition: all 0.4s;
}

.navbar-brand {
  margin-right: 0;
}

.navbar-dark .navbar-toggler {
  border-color: rgba(255, 255, 255, .7);
  position: absolute;
  right: 0;
  top: 7px;
}

.button-container {
  width: 56px;
  height: 40px;
  display: none;
}

.navbar-expand-lg .navbar-nav .nav-link {
  padding: 0;
  margin-left: 0.9rem;
  margin-right: 0.9rem;
  color: #fff;
  transition: all 0.3s;
  position: relative;
  text-decoration: none;
  white-space: nowrap;
  &:hover {
    color: #fff;
  }
  &::before {
    display: block;
    position: absolute;
    content: "";
    height: 3px;
    width: 0;
    background-color: $colorYellow;
    transition: width .3s ease-in-out, left .3s ease-in-out;
    left: 50%;
    bottom: 0;
  }
  &::after {
    display: block;
    position: absolute;
    content: "";
    height: 3px;
    width: 0;
    background-color: $colorYellow;
    transition: width .3s ease-in-out;
    left: 50%;
    bottom: 0;
  }
  &:hover::before {
    width: 50%;
    left: 0;
  }
  &:hover::after {
    width: 50%;
  }
}

.navbar-dark .navbar-nav .nav-link.active {
  &::before {
    width: 50%;
    left: 0;
  }
  &::after {
    width: 50%;
  }
}

.header__contact-phone {
  font-family: MyriadProBold, sans-serif;
  font-size: 28px;
  line-height: 1.2;
  white-space: nowrap;
  a {
    color: $colorRed;
    transition: color 0.5s;
    display: block;
    text-align: right;
    &:hover{
      color: $colorYellow;
      text-decoration: none;
    }
  }
}

.header__contact-callback {
  font-size: 20px;
  text-align: right;
  line-height: 1;
  cursor: pointer;
  transition: color 0.3s;
  &:hover {
    color: $colorYellow
  }
}

#main {
  background: url(../img/bg-main.jpg) center right no-repeat;
  background-size: cover;
  color: #fff;
  padding-bottom: 98px;
  padding-top: 168px;
  .col-lg-8 {
    margin-left: -15px;
  }
  .items {
    text-align: center;
    display: flex;
    justify-content: space-between;
    font-size: 23px;
    line-height: 1.25;
  }
  .items__item {
    flex-basis: 290px;
    &:nth-child(1), &:nth-child(3) {
      flex-basis: 150px;
    }
    .image {
      width: auto;
      height: auto;
    }
    .text {
      margin-top: 18px;
    }
  }
  .btn-callback {
    background: $colorYellow;
    font-family: MyriadProBold, sans-serif;
    font-size: 30px;
    text-transform: uppercase;
    border-radius: 30px;
    line-height: 1;
    padding: 20px 57px 13px;
    margin-top: 59px;
    transition: all 0.3s;
    &:hover {
      background: #fedc55;
    }
    &:active {
      background: #e4bd11;
    }
  }
}

#service-auto {
  .bg-grey {
    padding: 89px 0 29px;
  }
  .bg-white {
    padding: 60px 0;
  }
  .items-3, .items-4 {
    display: flex;
    align-items: center;

    img {
      height: auto;
      width: auto;
      filter: grayscale(100%);
      transition: all 0.5s;
      &:hover {
        filter: grayscale(0%);
      }
    }
  }
  .items-4 {
    justify-content: space-between;
    margin-bottom: 78px;
  }
  .items-3 {
    justify-content: center;
    .image {
      margin: 0 30px;
    }
  }
}

#service-trailer {
  h2 {
    margin-bottom: 39px;
  }
  .bg-grey {
    padding: 24px 0 87px;
  }
  .items {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: flex-start;
  }
  .item {
    position: relative;
    &:nth-child(1) {
      z-index: 1;
      margin-right: -35px;
      margin-top: 12px;
    }
    &:nth-child(2) {
      z-index: 2;
    }
    &:nth-child(3) {
      z-index: 3;
      margin-left: -17px;
      margin-top: 15px;
    }
  }
  img {
    width: auto;
    height: auto;
    transition: filter 0.5s;
    filter: grayscale(100%);
    &:hover {
      filter: grayscale(0%);
    }
  }
}

#services {
  padding: 79px 0;
  h2 {
    margin-bottom: 80px;
  }
  h3 {
    font-size: 20px;
    margin: 0;
  }
  h4 {
    margin-bottom: 23px;
    text-align: center;
  }
  .items {
    font-size: 20px;
    font-family: MyriadProBold, sans-serif;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 13px;
  }
  .item {
    display: flex;
    align-items: center;
    flex-basis: 33.33%;
    padding: 0 8.5px;
    margin: 0 0 60px;
    &:hover {
      img {
        filter: grayscale(100%);
        transition: all 1s;
      }
    }
  }
  .image {
    margin-right: 17px;
  }
  .label-cbx {
    text-align: center;
  }
  .form-group {
    margin-bottom: 13px;
  }
  img {
    width: auto;
    height: auto;
  }
  .checkbox-block {
    text-align: center;
  }
}

#advantages {
  background: url(../img/bg-advantages.jpg) bottom center no-repeat;
  background-size: cover;
  color: #fff;
  padding: 82px 0 51px;
  h2 {
    margin-bottom: 74px;
  }
  h4 {
    font-size: 20px;
    font-family: MyriadProRegular, sans-serif;
    margin-top: 20px;
  }
  .items {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
  }
  .item {
    display: flex;
    flex-direction: column;
    margin-bottom: 23px;
    padding: 15px 0;
    flex-basis: 25%;
    justify-content: flex-start;
  }
  img {
    width: auto;
    height: auto;
  }
}

#payment-methods {
  padding: 80px 0 80px;
  h2 {
    margin-bottom: 90px;
  }
  h3 {
    font-family: MyriadProBold, sans-serif;
    font-size: 20px;
    text-align: center;
    margin-top: 20px;
    text-transform: uppercase;
  }
  .items {
    display: flex;
    align-items: center;
    justify-content: space-evenly;

  }
  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

#question {
  position: relative;
  padding: 0 0 95px 0;
  &:before, &:after{
    content: '' ;
    position: absolute;
    background: #ececec;
    width: 100%;
    z-index: -1;
  }
  &:before{
    height: 110px;
  }
  &:after{
    height: 194px;
  }
  h2 {
    margin-bottom: 23px;
    margin-top: 78px;
  }
  h3 {
    font-size: 30px;
    line-height: 46px;
    font-family: MyriadProBold, sans-serif;
    text-align: center;
    margin-bottom: 23px;
  }
  form{
    max-width: 360px;
    position: relative;
    margin: 0 auto -117px;
    .form-group{
      margin-bottom: 30px;
    }
  }

  .image{
    top: -50px;
    left: -25px;
    position: absolute;
    filter: grayscale(80%);
    width: auto;
    height: auto;
    will-change: visibility, display;
  }
}

#contact {
  color: #fff;
  padding: 97px 0 0;
  .row-top{
    background: url(../img/bg-contact-dark.jpg) no-repeat top center;
    background-size: cover;
  }
  .row-map{
    background: url(../img/bg-contact-striped.jpg) no-repeat top center;
    background-size: cover;
    padding-bottom: 100px;
  }
  h2{
    margin-bottom: 85px;
    margin-top: 80px;
  }
  h5{
    font-size: 20px;
    line-height: 29px;
    font-family: MyriadProBold, sans-serif;
    max-width: 617px;
    text-align: center;
    margin: 0 auto 20px;
  }
}

footer {
  padding: 11px 0 35px;
  color: #fff;
  background: url(../img/bg-contact-dark.jpg) no-repeat top center;
  background-size: cover;
  .col-md-4{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .company-name {
    font-size: 20px;
    margin-top: 20px;
    #btn-policy{
      cursor: pointer;
      color: $colorYellow;
      transition: color 0.5s;
      &:hover{
        color: #fff;
      }
    }
  }
  .developer{
    color: #fff;
    transition: color 0.5s;
    &:hover{
      color: $colorLightRed;
      text-decoration: none;
    }
  }
  .brand{
    text-align: center;
  }
}
.footer__contact-phone {
  font-family: MyriadProBold, sans-serif;
  font-size: 28px;
  line-height: 1.2;
  white-space: nowrap;
  a {
    display: block;
    color: $colorYellow;
    text-decoration: none;
    transition: all 0.5s;
    &:hover{
      color: #fff;
    }
  }
}

.footer__contact{
  text-align: right;
  font-size: 20px;
  line-height: 22px;
  margin-top: 35px;
}

.footer__contact-callback{
  transition: color 0.5s;
  cursor: pointer;
  &:hover{
    color: $colorYellow;
    text-decoration: none;
  }
}

@import 'media';