@import "vars";
.modal-sm{
  max-width: 418px;
  .modal-body{
    padding-bottom: 21px;
    form{
      max-width: 360px;
      margin: 0 auto;
    }
    .form-group{
      margin-bottom: 30px;
    }
    h3{
      font-family: MyriadProBold, sans-serif;
      text-align: center;
      font-size: 36px;
      line-height: 55px;
      margin-top: 35px;
      margin-bottom: 27px;
      display: none;
    }
  }
}
.modal-title{
  font-family: MyriadProBold, sans-serif;
  text-align: center;
  line-height: 1.4;
  margin-top: 15px;
  margin-bottom: 19px;
}

.modal-header{
  padding: 0;
  border-bottom: none;
  position: relative;
  .close{
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 99;
    font-size: 14px;
    .border-close{
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 20px;
      width: 20px;
      border: 2px solid #707070;
    }
  }
}

.modal-lg{

}




