@import 'vars';
#label-cbx,
#label-cbx-2,
#label-cbx-3 {
  user-select: none;
  cursor: pointer;
  margin-bottom: 0;
  font-size: 15px;
  color: #5a5a5a;
  input:checked {
    & + .checkbox {
      border-color: $colorRed;
      svg {
        path {
          fill: $colorRed; }
        polyline {
          stroke-dashoffset: 0; } } } }
  &:hover {
    .checkbox {
      svg {
        path {
          stroke-dashoffset: 0; } } } }

  .checkbox {
    position: relative;
    top: 2px;
    float: left;
    margin-right: 8px;
    width: 20px;
    height: 20px;
    border: 2px solid #C8CCD4;
    border-radius: 3px;
    svg {
      position: absolute;
      top: -2px;
      left: -2px;
      path {
        fill: none;
        stroke: $colorRed;
        stroke-width: 2px;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-dasharray: 71px;
        stroke-dashoffset: 71px;
        transition: all .6s ease; }
      polyline {
        fill: none;
        stroke: #FFF;
        stroke-width: 2px;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-dasharray: 18px;
        stroke-dashoffset: 18px;
        transition: all .3s ease; } } }

  > span {
    pointer-events: none;
    vertical-align: middle; } }

.invisible {
  position: absolute;
  z-index: -1;
  width: 0;
  height: 0;
  opacity: 0; }
