@import "vars";
@media screen and (max-width: 1734.98px) {
  #question {
    .image {
      top: 85px;
      img{
        width: 560px;
      }
    }
  }
}
@media screen and (max-width: 1284.98px) {
  #services {
    h3{
      font-size: 18px;
      max-width: 242px;
    }
    .item{
      flex-basis: 35%;
    }
  }
  #question {
    .image {
      img{
        width: 520px;
      }
    }
  }
}

@media screen and (max-width: 1198.98px) {
  header {
    .navbar-brand {
      img {
        width: 100px;
      }
    }
  }
  #main {
    padding: 100px 0 70px;
    .items {
      font-size: 20px;
    }
    .items__item {
      img {
        height: 70px;
      }
    }
    .btn-callback {
      font-size: 24px;
      margin: 59px auto 0;
      display: block;
    }
  }
  h1 {
    text-align: center;
    font-size: 38px;
    line-height: 57px;
  }
  h2 {
    font-size: 34px;
    line-height: 56px;
    &.bordered-bottom {
      &:after {
        height: 4px;
        width: 300px;
        bottom: -8px;
        margin-left: -150px;
      }
    }
  }
  .nav-link {
    font-size: 16px;
  }
  .header__contact-phone {
    font-size: 24px;
  }
  .header__contact-callback {
    font-size: 16px;
  }
  #service-auto {
    .items-4 {
      img {
        max-height: 100px;
      }
    }
    .items-3 {
      img {
        max-height: 45px;
      }
    }
    .bg-grey {
      padding: 64px 0 54px;
    }
  }
  #service-trailer{
    .bg-grey {
      padding: 64px 0 54px;
    }
  }
  #services {
    padding: 59px 0;
    h3{
      font-size: 17px;
      max-width: 220px;
    }
    h4{
      font-size: 26px;
    }
    .item{
      flex-basis: 40%;
    }
    img{
      width: 65px;
    }
    input[type=submit] {
      font-size: 20px;
    }
  }
  #advantages{
    padding: 82px 0 51px;
    h4{
      font-size: 18px;
    }
    img{
      height: 80px;
    }
  }
  #question {
    .image {
      img{
        width: 400px;
      }
    }
  }
  footer{
    .company-name{
      font-size: 18px;
      line-height: 39px;
      margin-top: 34px;
    }
  }
  .footer__contact{
    margin-top: 34px;
    font-size: 18px;
  }
}

@media screen and (max-width: 991.98px) {
  #question .image {
    display: none;
    visibility: hidden;
  }
  h1 {
    font-size: 30px;
    line-height: 43px;
  }
  h2 {
    font-size: 30px;
    line-height: 48px;
    &.bordered-bottom {
      &:after {
        height: 4px;
        width: 260px;
        bottom: -8px;
        margin-left: -130px;
      }
    }
  }
  .button-container{
    display: block;
  }
  .header__contact{
    position: absolute;
    left: 50%;
    margin-left: -80px;
    top: 10px;
  }
  .header__contact-callback {
    text-align: center;
    margin-top: 10px;
  }

  .navbar{
    padding-bottom: 20px !important;
  }
  .nav-link {
    font-size: 20px;
    margin-bottom: 15px;
  }
  .navbar-collapse{
    margin-top: 50px;
  }
  #main {
    .items {
      font-size: 18px;
    }
    .items__item {
      img {
        height: 65px;
      }
    }
  }
  #service-trailer{
    .bg-grey{
      padding: 47px 0 50px;
    }
    img{
      width: 90%;
    }
    .item:nth-child(1){
      margin-right: -47px;
    }
    .item:nth-child(3){
      margin-left: -47px;
    }
  }
  #services {
    h3{
      font-size: 16px;
      max-width: 220px;
    }
    .item{
      flex-basis: 49%;
      margin: 0 0 60px;
    }
    img{
      width: 60px;
    }

    input[type=submit] {
      font-size: 15px;
    }
    form{
      max-width: 300px;
      margin: 0 auto;
      .form-group{
        margin-bottom: 30px;
      }
    }
  }
  #advantages{
    h4{
      font-size: 17px;
    }
    padding: 58px 0 40px;
    .items{

    }
    .item{
      flex-basis: 33.33%;
    }
  }
  footer{
    .logo{
      width: 200px;
    }
    .company-name{
      font-size: 16px;
      line-height: 20px;
      margin-top: 17px;
    }
  }
  .footer__contact{
    margin-top: 13px;
    font-size: 16px;
  }
  .footer__contact-phone{
    font-size: 25px;
  }
}

@media screen and (max-width: 767.98px) {
  h1 {
    font-size: 28px;
    line-height: 39px;
  }
  h2 {
    font-size: 28px;
    line-height: 39px;
  }
  .header__contact{
    margin-left: -80px;
  }

  #main {
    background: url(../img/bg-main.jpg) -880px top no-repeat;
    padding: 100px 0 50px;
    .items {
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }
    .items__item {
      max-width: 290px;
      flex-basis: auto;
      margin-bottom: 25px;
      img {
        height: 80px;
      }
    }
    .btn-callback {
      font-size: 20px;
      padding: 20px 28px 13px;
      margin: 13px auto 0;
    }
  }
  #service-auto{
    .items-4 {
      img{
        max-height: 80px;
      }
    }
    .items-3 {
      img{
        max-height: 30px;
      }
    }
  }
  #service-auto .bg-grey, #service-trailer .bg-grey {
    padding: 44px 0 34px;
  }

  #services {
    h3{
      text-align: center;
      margin-top: 20px;
      font-size: 14px;
      max-width: 180px;
    }
    .items{
      align-items: flex-start;
    }
    .item{
      flex-basis: 49%;
      margin: 0 0 40px;
      flex-direction: column;
      justify-content: flex-start;
    }
    img{
      width: 60px;
    }
    input[type=submit] {
      font-size: 15px;
    }
  }
  #advantages{
    h4{
      font-size: 14px;
    }
    padding: 58px 0 30px;
    .items{

    }
    .item{
      flex-basis: 33.33%;
    }
    img{
      height: 70px;
    }
  }
  #payment-methods{
    h3{
      font-size: 16px;
    }
    img{
      width: 70px;
    }
  }
  footer{
    text-align: center;
    .company-name{
      font-size: 18px;
      line-height: 27px;
      margin-bottom: 25px;

    }
  }
  .footer__contact{
    text-align: center;
    font-size: 18px;
    line-height: 27px;
  }
}

@media screen and (max-width: 575.98px) {
  h1 {
    font-size: 25px;
    line-height: 36px;
  }
  h2 {
    font-size: 24px;
    line-height: 36px;
    &.bordered-bottom {
      &:after {
        height: 3px;
        width: 210px;
        margin-left: -105px;
      }
    }
  }
  .navbar {
    margin-left: 0;
    margin-right: 0;
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .header__contact{
    margin-left: -74px;
  }
  #main {
    background: url(../img/bg-main.jpg) -1014px top no-repeat;
    .btn-callback {
      font-size: 18px;
      padding: 20px 21px 13px;
    }
  }
  #service-auto{
    .bg-white{
      padding: 60px 0 0;
    }
    .items-4 {
      justify-content: center;
      flex-wrap: wrap;
      margin-bottom: 0;
      .image{
        margin: 0 15px;
        margin-bottom: 50px;
      }
      img{
        max-height: 80px;
      }
    }
    .items-3 {
      flex-wrap: wrap;
      .image{
        margin-bottom: 50px;
      }
      img{
        max-height: 30px;
      }
    }
  }
  #services {
    h3{
      text-align: center;
      margin-top: 20px;
      font-size: 14px;
      max-width: 180px;
    }
    .items{
      align-items: flex-start;
    }
    .item{
      flex-basis: 100%;
      margin: 0 0 40px;
    }
    img{
      width: 60px;
    }
  }
  #advantages{
    h4{
      font-size: 17px;
      max-width: 200px;
    }
    padding: 58px 0 30px;
    .item{
      flex-basis: 50%;
      align-items: center;
    }
    img{
      height: 70px;
    }
  }
}

@media screen and (max-width: 479.98px) {
  h1 {
    font-size: 20px;
    line-height: 32px;
  }
  h2 {
    font-size: 21px;
    line-height: 29px;
    &.bordered-bottom {
      &:after {
        width: 180px;
        margin-left: -90px;
      }
    }
  }
  .header__contact{
    margin-left: -67px;
  }
  header .navbar-brand img {
    width: 75px;
  }
  .header__contact-phone {
    font-size: 20px;
  }
  #main{
    background: url(../img/bg-main.jpg) -1123px top no-repeat;
  }

  #services{
    h4{
      font-size: 24px;
    }
  }
  #advantages{
    h4{
      max-width: 270px;
    }
    .item{
      flex-basis: 100%;
    }
  }
  #payment-methods{
    .items{
      flex-wrap: wrap;
      justify-content: center;
    }
    .item{
      flex-basis: 100%;
      margin-bottom: 25px;
    }
    h3{
      font-size: 20px;
    }
    img{
      width: auto;
    }
  }
}

@media screen and (max-width: 369.98px) {
  h2 {
    font-size: 18px;
    line-height: 25px;
    &.bordered-bottom {
      &:after {
        width: 140px;
        margin-left: -75px;
      }
    }
  }

  header .navbar-brand img {
    width: 70px;
  }
  .header__contact-phone {
    font-size: 18px;
  }
  #services{
    h4{
      font-size: 20px;
    }
  }
}